import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import blackArrow from '../img/arrow-down-black.png';

const FormRow = styled.div`
  @media only screen and (min-width: 600px) {
    display: flex;
  }
`;

const FormColumn = styled.div`
  width: 100%;

  .field {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .required {
    color: #d73131;
  }

  .submit-button {
    text-transform: uppercase;
    color: #fff;
    padding: 0.5rem 3rem;
    display: block;
    border: 1px solid #bd8b13;
    border-radius: 3px;
    background-color: #bd8b13;
    font-size: 0.9rem;
    font-weight: 700;
    display: inline-block;
    cursor: pointer;

    -webkit-transition: background-color 0.2s linear, border-color 0.2s linear,
      color 0.2s linear;
    transition: background-color 0.2s linear, border-color 0.2s linear,
      color 0.2s linear;

    &:focus {
      box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
      outline: 2px dotted transparent;
      outline-offset: 2px;
    }

    &:hover {
      border-color: #a67b10;
      background-color: #a67b10;
    }

    &:active {
      background-color: #d9ae43;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
      text-align: center;
    }
  }

  input:not([type='radio']),
  textarea,
  select {
    padding: 0 12px;
    height: 2.25rem;
    line-height: 2.25rem;
    width: 100%;
    border: thin solid #f0ede4;
  }

  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    background-color: white;
    background-image: url(${blackArrow});

    background-repeat: no-repeat;
    padding-right: 20px;
    background-size: 15px;
    padding-right: 25px;
    background-position: right 5px center;

    .default {
      color: green;
    }
  }

  textarea {
    resize: none;
    height: 7.5rem;
  }

  .radios {
    label {
      margin-right: 1.5rem;
      input {
        margin-right: 0.5rem;
        position: relative;
        top: -1px;
      }
    }
  }

  @media only screen and (min-width: 600px) {
    &:first-child {
      padding-right: 0.625rem;
    }

    &:last-child {
      padding-left: 0.625rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    textarea {
      height: 10.95rem;
      &.available-units {
        height: 3rem;
      }
    }
  }
`;


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    let renderUnitsOfInterest = null;

    if (this.props.units.length > 0) {
      const unitsOptions = this.props.units.map(unit => {
        return (
          <option key={unit.node.id} value={unit.node.frontmatter.title}>
            {unit.node.frontmatter.title}
          </option>
        );
      });

      renderUnitsOfInterest = (
        <div className="field units-of-interest">
          <label className="label" htmlFor={'unit'}>
            <strong>Unit of Interest</strong>
          </label>
          <select
            className="input"
            name={'unit'}
            onBlur={this.handleChange}
            id={'unit'}
          >
            <option className={'default'} key={0} value={'no unit selected'}>
              Select a Unit
            </option>
            {unitsOptions}
          </select>
        </div>
      );
    }

  
    

    

    return (
      <section className="section">
        <div className="container">
          <div className="content">
            <h2 style={{ marginTop: 0, color: '#181816' }}>
              Interested Buyers
            </h2>
            <form
              name="interested-buyers"
              method="post"
              action="/thank-you/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
              style={{
                marginBottom: 0,
              }}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>
              <FormRow>
                <FormColumn>

                  <div className="field">
                    <label className="label" htmlFor={'firstName'}>
                      <strong>
                        First Name<span className="required">*</span>
                      </strong>
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'firstName'}
                        onChange={this.handleChange}
                        id={'firstName'}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor={'lastName'}>
                      <strong>
                        Last Name<span className="required">*</span>
                      </strong>
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'lastName'}
                        onChange={this.handleChange}
                        id={'lastName'}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor={'email'}>
                      <strong>
                        Email Address<span className="required">*</span>
                      </strong>
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'email'}
                        name={'email'}
                        onChange={this.handleChange}
                        id={'email'}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor={'phone'}>
                      <strong>Phone</strong>
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'tel'}
                        name={'phone'}
                        onChange={this.handleChange}
                        id={'phone'}
                        required={false}
                      />
                    </div>
                  </div>

                  <div className="field radios">
                    <p style={{ marginBottom: 0 }}>
                      <strong style={{ position: 'relative' }}>
                        Are you now (or do you plan to be) a Cal Poly employee?
                        <span
                          style={{ position: 'absolute' }}
                          className="required"
                        >
                          *
                        </span>
                      </strong>
                    </p>
                    <label>
                      <input
                        type="radio"
                        name="currentOrFutureEmployee"
                        value="yes"
                        checked={this.state.currentOrFutureEmployee === 'yes'}
                        onChange={this.handleChange}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="currentOrFutureEmployee"
                        value="no"
                        checked={this.state.currentOrFutureEmployee === 'no'}
                        onChange={this.handleChange}
                      />
                      No
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="currentOrFutureEmployee"
                        value="uncertain"
                        checked={
                          this.state.currentOrFutureEmployee === 'uncertain'
                        }
                        onChange={this.handleChange}
                      />
                      Uncertain
                    </label>
                  </div>  

                  {/* {this.state.community === "Bella Montana" ? renderUnitsOfInterest : null} */}
                  {this.state.currentOrFutureEmployee === "yes" ? 
                    <div className="field">
                    <label className="label" htmlFor={'startdate'}>
                      <strong>Please select employment start date, if known.</strong>
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'date'}
                        name={'startdate'}
                        onChange={this.handleChange}
                        id={'startDate'}
                      />
                    </div>
                  </div>
                  : null}



                </FormColumn>

                <FormColumn>

                <div className="field classification">
                    <label className="label" htmlFor={'classification'}>
                      <strong>Please select your employment classification
                      <span
                          style={{ position: 'absolute' }}
                          className="required"
                        >
                          *
                        </span>
                      </strong>
                    </label>
                    <select
                      className="input"
                      name={'classification'}
                      onBlur={this.handleChange}
                      id={'classification'}
                      required={true}
                    >
                      <option className={'default'} key={0} value={'no classification selected'} selected disabled>
                        Select Employment Classification
                      </option>
                      <option className={'default'} key={1} value={'Cal Poly Faculty'}>
                        Cal Poly Faculty
                      </option>
                      <option className={'default'} key={2} value={'Cal Poly Lecturer'}>
                        Cal Poly Lecturer
                      </option>
                      <option className={'default'} key={3} value={'Cal Poly Coach'}>
                        Cal Poly Coach
                      </option>
                      <option className={'default'} key={4} value={'Cal Poly Management (MPP)'}>
                        Cal Poly Management (MPP)
                      </option>
                      <option className={'default'} key={5} value={'Cal Poly Staff'}>
                        Cal Poly Staff
                      </option>
                      <option className={'default'} key={6} value={'Cal Poly Auxiliary Employee (e.g., ASI, Cal Poly Athletics, Cal Poly Foundation, etc.)'}>
                        Cal Poly Auxiliary Employee (e.g., ASI, Cal Poly Athletics, Cal Poly Foundation, etc.)
                      </option>
                      <option className={'default'} key={7} value={'Cal Poly or Auxiliary Employee One-Year Appointments'}>
                        Cal Poly or Auxiliary Employee One-Year Appointments
                      </option>
                      <option className={'default'} key={8} value={'Other CSU or CSU Auxiliary Employee'}>
                        Other CSU or CSU Auxiliary Employee
                      </option>
                      <option className={'default'} key={9} value={'SLO County Public Education Employee'}>
                        SLO County Public Education Employee
                      </option>
                      <option className={'default'} key={10} value={'SLO County Public Agency Employee'}>
                        SLO County Public Agency Employee
                      </option>
                      <option className={'default'} key={10} value={'General Public'}>
                        General Public
                      </option>
                      
                    </select>
                  </div>

                <div className="field radios">
                    <p style={{ marginBottom: 0 }}>
                      <strong style={{ position: 'relative' }}>
                        Are you currently:
                        </strong>
                    </p>
                    <label>
                      <input
                        type="radio"
                        name="rentingOrOwn"
                        value="renting"
                        checked={this.state.rentingOrOwn === 'renting'}
                        onChange={this.handleChange}
                      />
                      Renting
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="rentingOrOwn"
                        value="own"
                        checked={this.state.rentingOrOwn === 'own'}
                        onChange={this.handleChange}
                      />
                      Own
                    </label>
                  </div>


                  <div className="field community">
                    <label className="label" htmlFor={'community'}>
                      <strong>Community of Interest</strong>
                    </label>
                    <select
                      className="input"
                      name={'community'}
                      onBlur={this.handleChange}
                      id={'community'}
                      required={true}
                    >
                      <option className={'default'} key={0} value={'no community selected'} selected disabled>
                        Select Community of Interest
                      </option>
                      <option className={'default'} key={1} value={'Bella Montana'}>
                        Bella Montana
                      </option>
                      <option className={'default'} key={2} value={'Slack and Grand'}>
                        Slack and Grand
                      </option>
                      <option className={'default'} key={3} value={'First Available'}>
                      First Available
                      </option>

                      
                      
                    </select>
                  </div>

                  
   
                {this.state.community === "Bella Montana" ? renderUnitsOfInterest : null}

  
                  <div className={`field`}>
                    <label className="label" htmlFor={'message'}>
                      <strong>Other Comments/Notes</strong>
                    </label>
                    <div className="control">
                      <textarea
                        className={`textarea ${
                          this.props.units.length > 0 ? 'available-units' : null
                        }`}
                        name={'message'}
                        onChange={this.handleChange}
                        id={'message'}
                        required={false}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <p style={{ marginBottom: '0.5rem' }} className="required">
                      <strong>* Required</strong>
                    </p>
                    <button className="submit-button is-link" type="submit">
                      Submit
                    </button>
                  </div>
                </FormColumn>
              </FormRow>
            </form>
          </div>
        </div>
      </section>
    );
  }
}
