import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import Img from 'gatsby-image';
import { Link as SmoothLink } from 'react-scroll';

import Layout from '../components/Layout';
import InterestedBuyers from '../components/InterestedBuyers';
import {
  Row,
  Row8Col,
  Row10Col,
  BackpageBannerWrapper,
  BackpageBanner,
  PageTitle,
  MainPageContent,
} from '../components/CustomComponents';

const AvailableHomesTable = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media only screen and (min-width: 951px) {
    > * {
      width: 32%;
      margin-right: 2%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    > * {
      width: 48%;
      margin-right: 4%;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    > * {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const SingleHome = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 1.5rem;

  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  .home-info {
    padding: 1rem;
    > *:first-child {
      margin-top: 0;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  .realtor-info {
    p {
      margin-bottom: 0rem;
    }

    a {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .alt-realtor {
    margin-top: 1rem;
  }

  .featured-image {
    height: 250px;
    overflow: hidden;

    .image-link {
      display: block;
      padding: 0;
      -webkit-transition: transform 0.2s ease-out;
      transition: transform 0.2s ease-out;
      background: none;
      height: 100%;
      border-bottom: 1px solid #e8e8e8;

      img,
      .gatsby-image-wrapper {
        height: 100%;
      }

      &:hover,
      &active {
        transform: scale(1.025);
      }
    }

    * {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    .featured-image {
      height: 250px;
    }
  }
`;

const h2Styles = {
  marginTop: 0,
  fontFamily: 'Source Sans Pro, sans-serif',
  fontWeight: 700,
  lineHeight: '2.5rem',
};

const h2SpanStyles = {
  fontWeight: 400,
  textTransform: 'uppercase',
  color: '#428269',
};

export default function AvailableHomes({ data, location }) {
  const homes = data.AllHomes.edges.map(home => {
    const {
      title,
      slug,
      price,
      date_listed,
      date_available,
      featured_image,
      realtor_name,
      realtor_email,
      realtor_phone,
      secondary_realtor_phone,
      alt_realtor_name,
      alt_realtor_email,
      sale_or_lease,
    } = home.node.frontmatter;

    const cleanRealtorPhone = realtor_phone
      ? realtor_phone.replace(/[^\d]/g, '')
      : null;

    const cleanSecondaryRealtorPhone = secondary_realtor_phone
      ? secondary_realtor_phone.replace(/[^\d]/g, '')
      : null;

    const realtorInformation = (
      <div>
        <hr style={{ margin: '1rem 0', background: '#d1d1d1' }} />
        <div className="realtor-info">
          <h5 style={{ marginTop: '1rem' }}>Contact Information</h5>
          {realtor_name ? (
            <p>
              <strong>{realtor_name}</strong>
            </p>
          ) : null}
          {realtor_email ? (
            <p>
              <a href={`mailto:${realtor_email}`}>{realtor_email}</a>
            </p>
          ) : null}
          {cleanRealtorPhone ? (
            <p>
              <a href={`tel:${cleanRealtorPhone}`}>{realtor_phone}</a>
            </p>
          ) : null}
          {cleanSecondaryRealtorPhone ? (
            <p>
              <a href={`tel:${cleanSecondaryRealtorPhone}`}>
                {secondary_realtor_phone}
              </a>
            </p>
          ) : null}
        </div>
      </div>
    );

    const altRealtorInformation = (
      <div>
        <div className="realtor-info alt-realtor">
          {alt_realtor_name ? (
            <p>
              <strong>{alt_realtor_name}</strong>
            </p>
          ) : null}

          {alt_realtor_email ? (
            <p>
              <a href={`mailto:${alt_realtor_email}`}>{alt_realtor_email}</a>
            </p>
          ) : null}
        </div>
      </div>
    );

    const id = uuid();
    return (
      <SingleHome key={id}>
        <div className="home-info">
          <h3>
            <Link to={slug}>{title}</Link>
          </h3>
          <h5 style={{ marginTop: '1rem' }}>
            <strong>{price}</strong>
          </h5>

          <div className="date-info">
            {sale_or_lease !== 'lease' && date_listed ? (
              <small>Date Listed: {date_listed}</small>
            ) : null}
            {sale_or_lease === 'lease' && date_available ? (
              <small>Date Available: {date_available}</small>
            ) : null}
          </div>

          {realtor_name || realtor_email || cleanRealtorPhone
            ? realtorInformation
            : null}
          {alt_realtor_name || alt_realtor_email ? altRealtorInformation : null}
        </div>
        <div className="featured-image">
          <Link to={slug} className="image-link">
            <Img fluid={featured_image.childImageSharp.fluid} />
          </Link>
        </div>
      </SingleHome>
    );
  });

  const HomesAreAvailable = (
    <div>
      <Row8Col>
        <h2 style={h2Styles}>
          Currently <span style={h2SpanStyles}>Available</span>
        </h2>
        <p>
          Buying or renting a Bella Monta&ntilde;a home is easy, check below for
          listings of homes available to buy or rent.
        </p>
      </Row8Col>
      <AvailableHomesTable>{homes}</AvailableHomesTable>
    </div>
  );

  const NoHomesAvailable = (
    <Row8Col>
      <h2 style={h2Styles}>
        <span style={h2SpanStyles}>No Homes</span> Currently Available
      </h2>
      <p>
        There are currently no homes available at this time. Please see our{' '}
        <SmoothLink
          activeClass="active"
          to="interested-buyers"
          spy={true}
          smooth={true}
          offset={-185}
          duration={500}
        >
          Interested Buyers
        </SmoothLink>{' '}
        section for more information.
      </p>
    </Row8Col>
  );

  const markdownRemark = data.PageInfo;

  return (
    <div>
      <Layout
        location={location}
        pageMetaTitle={markdownRemark.frontmatter.title}
        pageMetaDescription={markdownRemark.frontmatter.meta_description}
      >
        <BackpageBannerWrapper>
          <BackpageBanner
            fluid={markdownRemark.frontmatter.hero_image.childImageSharp.fluid}
          ></BackpageBanner>
        </BackpageBannerWrapper>
        <Row>
          <Row10Col>
            <MainPageContent>
              <Row8Col>
                <PageTitle>{markdownRemark.frontmatter.title}</PageTitle>
                <div
                  dangerouslySetInnerHTML={{
                    __html: markdownRemark.html,
                  }}
                />
              </Row8Col>
              {!!homes.length ? HomesAreAvailable : NoHomesAvailable}
            </MainPageContent>
          </Row10Col>
        </Row>
        <InterestedBuyers />
      </Layout>
    </div>
  );
}

export const availableHomesPageQuery = graphql`
  query availableHomesPageQuery($id: String!) {
    AllHomes: allMarkdownRemark(
      limit: 100
      filter: {
        frontmatter: {
          published: { ne: false }
          templateKey: { eq: "single-home" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            price
            date_listed
            date_available
            featured_image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            realtor_name
            realtor_email
            realtor_phone
            secondary_realtor_phone
            alt_realtor_name
            alt_realtor_email
            sale_or_lease
          }
        }
      }
    }
    PageInfo: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        hero_image {
          childImageSharp {
            fluid(maxHeight: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        meta_description
      }
    }
  }
`;
